<template>
  <Page class="page">
    <template v-if="status === 'pending'">
      <section class="highlight pt-8 py-12">
        <LytGridHighlightLeft :length="highlights.length">
          <template #item="{ idx }">
            <CardBasicSkeleton />
          </template>
        </LytGridHighlightLeft>
      </section>
    </template>
    <template v-else-if="status === 'error'"> </template>
    <template v-else>
      <section class="highlight pt-8 py-12">
        <LytGridHighlightLeft :length="highlights.length">
          <template #item="{ idx }">
            <template
              v-for="card in [getCardAt(highlights, idx)]"
              :key="card?.uuid"
            >
              <Card v-if="card" :="card" />
            </template>
          </template>
        </LytGridHighlightLeft>
      </section>

      <div v-if="topics" ref="striping">
        <section
          v-for="({ uuid, title, body, path, cards }, idx) in topics"
          :key="uuid"
          class="topic-section contain-override py-8 space-y-12"
          :class="`idx-${idx} ${idx % 2 ? 'even' : 'odd'}`"
        >
          <SectionHeader
            v-if="title"
            class="topic-header"
            :title="title"
            :path="path"
            :body="body"
          />
          <LytGridFourUp :length="cards.length">
            <template #item="{ idx }">
              <template
                v-for="card in [getCardAt(cards, idx)]"
                :key="card?.uuid"
              >
                <Card v-if="card" class="animate-on-appear" :="card" />
              </template>
            </template>
          </LytGridFourUp>
        </section>
      </div>
    </template>
    <Meta name="og:image" :content="ogImg" />
    <Meta name="twitter:image" :content="ogImg" />
  </Page>
</template>

<script lang="ts" setup>
import { get } from 'radash'
import { stripHtml } from 'string-strip-html'
import { getCardAt } from '~/utils/get-card'
import * as logger from '~/utils/logging'
import type { BasicCard, ContentSection } from '~/types'

const {
  public: {
    unity: { baseUrl: unityBaseUrl },
  },
} = useRuntimeConfig()
const { t, locale } = useI18n()

const { data, status, error } = await useFetch('/api/data/homepage', {
  params: { lang: locale },
})

if (unref(error)) {
  logger.error(`[homepage] error fetching data`, {
    lang: unref(locale),
    error: unref(error),
    status: unref(status),
    [`${import.meta.client ? 'isClient' : 'isServer'}`]: true,
  })

  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    cause: 'page-not-found',
    data: unref(error),
    fatal: true,
  })
}

if (!unref(data)) {
  logger.error(`[homepage] empty data response`, {
    lang: unref(locale),
    error: unref(error),
    status: unref(status),
    [`${import.meta.client ? 'isClient' : 'isServer'}`]: true,
  })

  throw createError({
    statusCode: 500,
    statusMessage: 'Server Error',
    cause: 'empty-data-response',
    data: unref(data),
    // fatal: true,
  })
}

const highlights = computed<BasicCard[]>(() => unref<any>(data).highlights)
const topics = computed<ContentSection[]>(() => unref<any>(data).topics)

const striping = ref()
useStriping(striping)

// handle SEO & Metadata
const ogImg = computed(() => {
  const h = unref(highlights)
  const _src = get<string>(h, '0.image.src')
  if (!_src) return ''

  const { src } = useOgImage(_src)

  return unref(src)
})

const ogDescription = stripHtml(unref(highlights)[0]?.body?.markup || '').result

useHead({
  title: t('title'),
  htmlAttrs: {
    lang: locale,
  },
})

const setI18nParams = useSetI18nParams()
setI18nParams({
  en: { slug: '/' },
  es: { slug: '/' },
})

useSeoMeta({
  ogUrl: '/',
  ogType: 'website',
  description: ogDescription,
  ogTitle: t('title'),
  ogDescription: ogDescription,
  twitterTitle: t('title'),
  twitterDescription: ogDescription,
  twitterCard: 'summary',
})

definePageMeta({
  layout: 'unity',
})
</script>

<i18n lang="json">
{
  "en": {
    "title": "Discover"
  },
  "es": {
    "title": "Descubrir"
  }
}
</i18n>

<style lang="postcss" scoped>
.odd {
  @apply bg-neutral-200 dark:bg-gray-700;
}
</style>
